import { configureStore } from '@reduxjs/toolkit';
import algoSlice from './algo-slice';


const store = configureStore({
    reducer: {
        algo: algoSlice.reducer
    }
});

export default store;

