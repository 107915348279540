export const ALGORITHM_LIST = [
    { id: "1",  text: "Simple Moving Average", normname: "SMA", properties: [{'name':'Slow Trend','value':12,'minvalue':6,'maxvalue':250},{'name':'Quick Trend','value':6,'minvalue':3,'maxvalue':200}] },
    { id: "2",  text: "Exponential Moving Average", normname: "EMA" , properties: [{'name':'Slow Trend','value':12,'minvalue':6,'maxvalue':250},{'name':'Quick Trend','value':6,'minvalue':3,'maxvalue':200}] },
    { id: "3",  text: "Hull Moving Average", normname: "HMA" , properties: [{'name':'Slow Trend','value':12,'minvalue':6,'maxvalue':250},{'name':'Quick Trend','value':6,'minvalue':3,'maxvalue':200}] },
    { id: "4",  text: "Relative Strength Index", normname: "RSI" , properties: [
        {'name':'RSI Period','value':14,'minvalue':6,'maxvalue':50},
        {'name':'Over Bougth Threshold','value':0.25,'minvalue':0.10,'maxvalue':0.50},
        {'name':'Over Sold Threshold','value':0.75,'minvalue':0.60,'maxvalue':0.85},
    ] },
    { id: "5",  text: "MACD", normname: "MACD" , properties: [
        {'name':'Short Period','value':3,'minvalue':6,'maxvalue':250},
        {'name':'Long Period','value':6,'minvalue':3,'maxvalue':200},
        {'name':'MADCD Signal Line','value':9,'minvalue':3,'maxvalue':200},
    ] },
    { id: "6",  text: "Vortex", normname: "VORTEX", properties: [{'name':'Vortex Length','value':14,'minvalue':6,'maxvalue':50},] },
  ];

export const UNDERLYING = [
    { id: "1", text: "EURUSD" },
    { id: "2", text: "GBPUSD" },
    { id: "3", text: "USDCHF" },
    { id: "4", text: "USDJPY" },
    { id: "5", text: "USDCAD" },
    { id: "6", text: "USDAUD" },
    { id: "7", text: "USDNZD" },
    { id: "8", text: "USDNOK" },
    { id: "9", text: "USDSEK" }
];

export const TRADING_FREQUENCY = [
    { id: "1", text: "HF" },
    { id: "2", text: "M1" },
    { id: "3", text: "M2" },
    { id: "4", text: "M5" },
    { id: "5", text: "H1" },
    { id: "6", text: "H2" },
    { id: "7", text: "H6" },
    { id: "8", text: "D1" },
    { id: "9", text: "D5" }
]

export const STOP_LOSS = [
    { id: "1", text: "Relative PnL Strategy", min: 0.0, max: 10.0 },
    { id: "2", text: "Absolute PnL Strategy", min: 0.0, max: 10.0 },
    { id: "3", text: "Relative Notional", min: 0.0, max: 10.0 },
    { id: "4", text: "Absolute Notional", min: 0.0, max: 10.0 },
]

export const TAKE_PROFIT = [
    { id: "1", text: "Relative PnL Strategy", min: 0.0, max: 10.0 },
    { id: "2", text: "Absolute PnL Strategy", min: 0.0, max: 10.0 },
    { id: "3", text: "Relative Notional", min: 0.0, max: 10.0 },
    { id: "4", text: "Absolute Notional", min: 20.0, max: 100.0 },
]