import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";
// plugin that creates slider
import Slider from "nouislider";
// react plugin that creates text editor
import ReactQuill from "react-quill";
// javascript plugin that creates nice dropzones for files
import Dropzone from "dropzone";
import ProgressBar from 'react-bootstrap/ProgressBar';

// Redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { algoActions } from "./../../../store/algo-slice.js";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import SimpleHeader from "./../../../components/Headers/SimpleHeader.js";
// react plugin that creates an input with badges
import TagsInput from "./../../../components/TagsInput/TagsInput.js";
import { 
  ALGORITHM_LIST, 
  TRADING_FREQUENCY, 
  UNDERLYING,
  STOP_LOSS,
  TAKE_PROFIT
} from "./config.js";
import {v4 as uuidv4} from 'uuid';
import AlgoCard from "./../../../components/Algocards/Algocard.js";

Dropzone.autoDiscover = false;


function CreateBacktesting() {

  const [strategyName, setStrategyName] = React.useState("");
  const [strategyId] = React.useState(uuidv4());
  const [notional, setNotional] = React.useState(100000)
  const [analysisHolder, setAnalysisHolder] = React.useState("robot@golem.io");
  const [ticker, setTicker] = React.useState("1");
  const [algorithms, setAlgorithms] = React.useState([]);
  const [selectedAlgo, setSelectedAlgo] = React.useState("1")
  const [tradingFrequency, setTradingFrequency] = React.useState("1");
  const [analysisPeriod, setAnalysisPeriod] = React.useState({});
  const [stopLoss, setStopLoss] = React.useState("1");
  const [takeProfit, setTakeProfit] = React.useState("1");

  const dispatch = useDispatch()
  const algosList = useSelector(state=>state.algo.algosList)
  const numAlgos = useSelector(state=>state.algo.numAlgos)

  const [yourName, setyourName] = React.useState(false);
  const [emailAddress, setemailAddress] = React.useState(false);
  const [location, setlocation] = React.useState(false);
  const [password, setpassword] = React.useState(false);
  const [paymentMethos, setpaymentMethos] = React.useState(false);
  const [phoneNumber, setphoneNumber] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [tagsinput, setTagsinput] = React.useState([
    "Bucharest",
    "Cluj",
    "Iasi",
    "Timisoara",
    "Piatra Neamt"
  ]);
  const [slider1Value, setSlider1Value] = React.useState("2.00");
  const [slider1MinValue, setSlider1MinValue] = React.useState(0.0)
  const [slider1MaxValue, setSlider1MaxValue] = React.useState(10.0)

  const [slider2Value, setSlider2Value] = React.useState("3.00");
  const [slider2MinValue, setSlider2MinValue] = React.useState(0.0)
  const [slider2MaxValue, setSlider2MaxValue] = React.useState(10.0)

  const [progessValue, setProgressValue] = React.useState("0.00");

  const [reactQuillText, setReactQuillText] = React.useState("");
  const slider1Ref = React.useRef(null);
  const slider2Ref = React.useRef(null);

  const handleAddAlgo = (id, algoname, parameters) => {
    dispatch(
      algoActions.addAlgo({
        id: id,
        algoname: algoname,
        parameters: parameters
      })
    )
  }

  // const handleDelete = (key) => {
  //   const newData = algoCards.filter((item) => item.key !== key);
  //   setAlgoCards(newData);
  // };

  React.useEffect(() => {
    Slider.create(slider1Ref.current, {
      start: [slider1Value],
      connect: [true, false],
      step: 0.01,
      range: { min: slider1MinValue, max: slider1MaxValue }
    }).on("update", function (values, handle) {
      setSlider1Value(values[0]);
    });
    Slider.create(slider2Ref.current, {
      start: [slider2Value],
      connect: [true, false],
      step: 0.01,
      range: { min: slider2MinValue, max: slider2MaxValue }
    }).on("update", function (values, handle) {
      setSlider2Value(values[0]);
    });
  }, []);
  const handleReactDatetimeChange = (who, date) => {
    if (
      startDate &&
      who === "endDate" &&
      new Date(startDate._d + "") > new Date(date._d + "")
    ) {
      setStartDate(date);
      setEndDate(date);
    } else if (
      endDate &&
      who === "startDate" &&
      new Date(endDate._d + "") < new Date(date._d + "")
    ) {
      setStartDate(date);
      setEndDate(date);
    } else {
      if (who === "startDate") {
        setStartDate(date);
      } else {
        setEndDate(date);
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate._d + "" !== endDate._d + "") {
      if (
        new Date(endDate._d + "") > new Date(date._d + "") &&
        new Date(startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  return (
    <>
      <SimpleHeader name="New Backtesting" parentName="Backtestings" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="6">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Backtestings References</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: yourName
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-user" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Strategy name"
                              type="text"
                              onChange={(e)=>setStrategyName(e.target.value)}
                              value={strategyName}
                              onFocus={(e) => setyourName(true)}
                              onBlur={(e) => setyourName(false)}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: emailAddress
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-envelope" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Strategy Unique Identifier"
                              type="text"
                              value={strategyId}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: paymentMethos
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-credit-card" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Notional"
                              type="text"
                              value={notional.toLocaleString(undefined, {maximumFractionDigits:2})}
                              onChange={(e)=>setNotional(e.target.value.toLocaleString(undefined, {maximumFractionDigits:2}))}
                              onFocus={(e) => setpaymentMethos(true)}
                              onBlur={(e) => setpaymentMethos(false)}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <small className="font-weight-bold">USD</small>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: phoneNumber
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-globe-americas" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Analysis Holder"
                              type="text"
                              value={analysisHolder}
                              onFocus={(e) => setphoneNumber(true)}
                              onBlur={(e) => setphoneNumber(false)}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>

                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h3 className="mb-0">FX Pairs Underlying</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Select2
                      className="form-control"
                      defaultValue={ticker}
                      options={{
                        placeholder: "Select"
                      }}
                      onChange={(e)=>setTicker(e.target.value)}
                      data={UNDERLYING}
                    />
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Algorithms Selection</h3>
                  <h5 className="mb-0">Select a strategy and click on the plus button to include it in your analysis</h5>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="12" xs="6">
                    <Button 
                      className="btn-success ni ni-fat-add" color="success" size="sm"
                      onClick={async () => {
                        handleAddAlgo(numAlgos+1,ALGORITHM_LIST[selectedAlgo-1]["normname"],ALGORITHM_LIST[selectedAlgo-1]["properties"])
                      }}
                    >
                    </Button>

                  </Col>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Select2
                      className="form-control"
                      defaultValue={"1"}
                      value={selectedAlgo}
                      options={{
                        placeholder: "Select"
                      }}
                      data={ALGORITHM_LIST}
                      onChange={(e)=>{setSelectedAlgo(e.target.value)}}
                    />
                  </Form>
                </CardBody>
              </Card>           
              {algosList.map((item, index)=>{
                return <AlgoCard id={item.id} algoname={item.algoname} parameters={item.parameters} />
              })}
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Backtesting Analysis Period</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row className="input-daterange datepicker align-items-center">
                      <Col xs={12} sm={6}>
                        <label className=" form-control-label">
                          Start date
                        </label>
                        <FormGroup>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Date Picker Here"
                            }}
                            value={startDate}
                            timeFormat={false}
                            onChange={(e) =>
                              handleReactDatetimeChange("startDate", e)
                            }
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              classes +=
                                getClassNameReactDatetimeDays(currentDate);
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={6}>
                        <FormGroup>
                          <label className=" form-control-label">
                            End date
                          </label>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Date Picker Here"
                            }}
                            value={endDate}
                            timeFormat={false}
                            onChange={(e) =>
                              handleReactDatetimeChange("endDate", e)
                            }
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              classes +=
                                getClassNameReactDatetimeDays(currentDate);
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Backtesting Description</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div
                      data-quill-placeholder="Quill WYSIWYG"
                      data-toggle="quill"
                    />
                    <ReactQuill
                      value={reactQuillText}
                      onChange={(value) => setReactQuillText(value)}
                      theme="snow"
                      modules={{
                        toolbar: [
                          ["bold", "italic"],
                          ["link", "blockquote", "code", "image"],
                          [
                            {
                              list: "ordered"
                            },
                            {
                              list: "bullet"
                            }
                          ]
                        ]
                      }}
                    />
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col lg="6">
            <div className="card-wrapper">
            <Card>
                <CardHeader>
                  <h3 className="mb-0">Trading Frequency</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Select2
                      className="form-control"
                      defaultValue={tradingFrequency}
                      options={{
                        placeholder: "Select"
                      }}
                      data={TRADING_FREQUENCY}
                      onChange={(e)=>{setTradingFrequency(e.target.value)}}
                    />
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Stop Loss in % of Reference</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Col lg="12">
                      <Label>Reference</Label>
                      <Select2
                        className="form-control"
                        defaultValue={stopLoss}
                        options={{
                          placeholder: "Select"
                        }}
                        data={STOP_LOSS}
                        onChange={(e)=>{setStopLoss(e.target.value)}}
                      />
                    </Col>

                    <div className="input-slider-container">
                      <div className="input-slider" ref={slider1Ref} />
                      <Row className="mt-3">
                        <Col xs="6">
                          <span className="range-slider-value">
                            {slider1Value}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Take Profit in % of Reference</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                  <Col lg="12">
                      <Label>Reference</Label>
                      <Select2
                      className="form-control"
                      defaultValue={takeProfit}
                      options={{
                        placeholder: "Select"
                      }}
                      data={TAKE_PROFIT}
                      onChange={(e)=>{
                        setTakeProfit(e.target.value);
                        setSlider2MinValue(TAKE_PROFIT[e.target.value-1].min);
                        setSlider2MaxValue(TAKE_PROFIT[e.target.value-1].max);                      
                      }}
                    />
                  </Col>
                  <div className="input-slider-container">
                      <div className="input-slider" ref={slider2Ref} />
                      <Row className="mt-3">
                        <Col xs="6">
                          <span className="range-slider-value">
                            {slider2Value}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Backtesting Analysis Check List</h3>
                  <h5 className="mb-0 text-alert">Analysis requires the following conditions to be satisfied</h5>
                </CardHeader>
                <CardBody>
                  {strategyName!==""?
                  <li className="ni ni-check-bold text-success">Strategy Name {strategyName}</li>
                  :<li className="ni ni-fat-remove text-alert">A name is required</li>}<br/>
                  { numAlgos>0?
                    <li className="ni ni-check-bold text-success">Backtestings have {numAlgos} {numAlgos>1?"Algorithms":"Algorithm"}</li>:
                    <li className="ni ni-fat-remove text-alert">Backtestings should have at least 1 Algorithm</li>
                    }<br/>
                  <li className="ni ni-fat-remove text-alert">Start Date Cannot be After End Date</li><br/>
                  <li className="ni ni-fat-remove text-alert">End Date cannot be beyond today</li><br/>
                </CardBody>
              </Card>
              <Card>
              <CardHeader>
                  <h3 className="mb-0">Run Analysis</h3>
                </CardHeader>
                <CardBody>
                  <Button 
                      className="md-0"
                      onClick={(e) => {for (let i=0; i<101;i++){setProgressValue(i*100.00/100)}; dispatch(algoActions.initialise())}}
                  >
                      Simulate
                    </Button>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Analysis Preparation Progress</h3>
                  {progessValue===100.00?
                    <a className="mb-0 text-success" href={"#/admin/single-backtesting/"+strategyId} >Your Report is Ready, You can see it <span  >here</span></a>
                  :<h5 className="mb-0 text-alert">Please don't leave this page before completion</h5>}
                </CardHeader>
                <CardBody>
                  <ProgressBar animated variant={progessValue===100.00?"success":"warning"} now={progessValue} label={`${progessValue}%`} />
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CreateBacktesting;
