import {createSlice} from "@reduxjs/toolkit";

const algoSlice = createSlice({
    name: 'algo',
    initialState: {
        algosList: [],
        numAlgos: 0
    },
    reducers: {
        addAlgo(state, action) {
            const newAlgo = action.payload;
            // const existingAlgo = state.algosList.find((item)=> item.id === newAlgo.id);
            state.algosList.push({
                id: newAlgo.id,
                algoname: newAlgo.algoname,
                parameters: newAlgo.parameters
            })
            state.numAlgos++;
        },
        removeAlgo(state, action) {
            const id = action.payload;
            state.algosList = state.algosList.filter(item=>item.id !== id);
            state.numAlgos--;
        }, 
        updateAlgoProperties (state, action) {
            const id = action.payload.id;
            const property = action.payload.property;
            const value = action.payload.value;
            function updateProperty(param) {
                if (param.name===property) {
                    param.value = value
                    return param
                }    
                return param           
            }
            function updateList(item) {
                if (item.id === id) {
                    item.parameters.map (param => updateProperty(param))
                    return item
                }
                return item
            }
            state.algosList = state.algosList.map(item => updateList(item))
        },
        initialise (state, ation) {
            state.algosList = []
        }
    }
});

export const algoActions = algoSlice.actions;

export default algoSlice;