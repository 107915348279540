import React from "react";
import { createContext } from "react";
import { 
    Card, 
    CardHeader, 
    CardBody,
    Form,
    Input,
    Button,
    Row,
    Col,
    Label,
    Table,
    Media,
    Badge,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledTooltip
} from "reactstrap";

import { useDispatch } from "react-redux";
import { algoActions } from "./../../store/algo-slice";

export default function AlgoCard({ id, algoname, parameters }) {


    const dispatch = useDispatch();


    const handleRemoveAlgo = () => {
        dispatch(algoActions.removeAlgo(id))
    }
    
    const handleUpdateValues = (value) => (e)  => {
        dispatch(algoActions.updateAlgoProperties({id: id, value: e.target.value, property: value}))
    }

    return (
        <div className="card-wrapper">

        <Card>
            <CardHeader>
                <h3 className="mb-0" >{algoname}</h3>
                <Col className="mt-3 mt-md-0 text-md-right" lg="12" xs="6">
                    <Button 
                        className="btn-danger ni ni-fat-delete " 
                        color="danger" 
                        size="sm"
                        onClick={handleRemoveAlgo}
                    >
                    </Button>
                </Col>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      Parameters
                    </th>
                    <th className="sort" data-sort="budget" scope="col">
                      value
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                        MinValue
                    </th>
                    <th className="sort" data-sort="completion" scope="col">
                        MaxValue
                    </th>
                  </tr>
                </thead>
                <tbody className="list">
                {parameters.map((item,index) => {
                    return (                  
                    <tr>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="name mb-0 text-sm">
                                {item.name}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td className="budget"><Input 
                            value={item.value}
                            onChange={handleUpdateValues(item.name)}
                        ></Input></td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-warning" />
                            <span className="status">{item.minvalue}</span>
                          </Badge>
                        </td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            <span className="status">{item.maxvalue}</span>
                          </Badge>
                        </td>
                      </tr>)
                })}
                </tbody>
              </Table>
        </Card>
        </div>
    );

}
