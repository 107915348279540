import Alternative from "./views/pages/dashboards/Alternative.js";
import Buttons from "./views/pages/components/Buttons.js";
import Calendar from "./views/pages/Calendar.js";
import Cards from "./views/pages/components/Cards.js";
import Charts from "./views/pages/Charts.js";
import Components from "./views/pages/forms/Components.js";
import Dashboard from "./views/pages/dashboards/Dashboard.js";
import Elements from "./views/pages/forms/Elements.js";
import Google from "./views/pages/maps/Google.js";
import Grid from "./views/pages/components/Grid.js";
import Icons from "./views/pages/components/Icons.js";
import Lock from "./views/pages/examples/Lock.js";
import Login from "./views/pages/examples/Login.js";
import Notifications from "./views/pages/components/Notifications.js";
import Pricing from "./views/pages/examples/Pricing.js";
import Profile from "./views/pages/examples/Profile.js";
import ReactBSTables from "./views/pages/tables/ReactBSTables.js";
import Register from "./views/pages/examples/Register.js";
import RTLSupport from "./views/pages/examples/RTLSupport.js";
import Sortable from "./views/pages/tables/Sortable.js";
import Tables from "./views/pages/tables/Tables.js";
import Timeline from "./views/pages/examples/Timeline.js";
import Typography from "./views/pages/components/Typography.js";
import Validation from "./views/pages/forms/Validation.js";
import Vector from "./views/pages/maps/Vector.js";
import Widgets from "./views/pages/Widgets.js";

import CockpitStrategies from "./views/pages/dashboards/CockpitStrategies";
import CockpitHedging from "./views/pages/dashboards/CockpitHedging";
import CockpitMarketMaking from "./views/pages/dashboards/CockpitMarketMaking";
import PromoteStrategy from "./views/pages/strategies/PromoteStrategy";
import MonitorPerformance from "./views/pages/strategies/MonitorPerformance";
import HardStopStrategy from "./views/pages/strategies/HardStopStrategies";
import CreateBacktesting from "./views/pages/backtestings/CreateBacktesting";
import ExploreBacktestings from "./views/pages/backtestings/ExploreBacktestings";
import BacktestingAnalysis from "./views/pages/backtestings/BacktestingAnalysis.js";
import BAMStatistics from "./views/pages/marketmaking/BAMStatistics.js";
import MadTradingRange from "./views/pages/marketmaking/MadTradingRange.js";
import OpenExposures from "./views/pages/hedging/OpenExposures.js";
import HedgingTrade from "./views/pages/hedging/HedgingTrade.js";
import HedgingReconciliation from "./views/pages/hedging/HedgingReconciliation.js";
import TradeReconciliation from "./views/pages/tradesmonitor/TradeReconciliation.js";
import TradeReconciliationOK from "./views/pages/tradesmonitor/TradeReconciliationOK.js";
import TradeReconciliationProblems from "./views/pages/tradesmonitor/TradeReconciliationProblem.js";

const routes = [
  {
    collapse: true,
    name: "Cockpit",
    icon: "ni ni-compass-04 text-primary",
    state: "cockpitCollapse",
    views: [
      {
        path: "/cockpit-strategies",
        name: "Proprietary Trading",
        miniName: "D",
        component: CockpitStrategies,
        layout: "/admin"
      },
      {
        path: "/cockpit-hedging",
        name: "Hedging",
        miniName: "D",
        component: CockpitHedging,
        layout: "/admin"
      },
      {
        path: "/cockpit-market-making",
        name: "Market Making",
        miniName: "D",
        component: CockpitMarketMaking,
        layout: "/admin"
      },
      {
        path: "/dashboard",
        name: "Trading",
        miniName: "D",
        component: Dashboard,
        layout: "/admin"
      },
      {
        path: "/alternative-dashboard",
        name: "Health Check",
        miniName: "A",
        component: Alternative,
        layout: "/admin"
      },
      {
        path: "/alternative-dashboard",
        name: "Trade Reconciliation",
        miniName: "A",
        component: Alternative,
        layout: "/admin"
      },
      {
        path: "/alternative-dashboard",
        name: "Risks Exposure",
        miniName: "A",
        component: Alternative,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Backtestings",
    icon: "ni ni-atom text-green",
    state: "backtestingCollapse",
    views: [
      {
        path: "/create-backtesting",
        name: "Create Backtesting",
        miniName: "BC",
        component: CreateBacktesting,
        layout: "/admin"
      },
      {
        path: "/explore-backtesting",
        name: "Explore Backtestings",
        miniName: "A",
        component: ExploreBacktestings,
        layout: "/admin"
      },
      {
        path: "/single-backtesting/:backtestingId",
        name: "Backtesting Analysis",
        miniName: "A",
        component: BacktestingAnalysis,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Strategies",
    icon: "ni ni-app text-orange",
    state: "strategyCollapse",
    views: [
      {
        path: "/promote-strategy",
        name: "Promote Strategy",
        miniName: "D",
        component: PromoteStrategy,
        layout: "/admin"
      },
      {
        path: "/monitor-live-strategies-performance",
        name: "Monitor Performance",
        miniName: "A",
        component: MonitorPerformance,
        layout: "/admin"
      },
      {
        path: "/hard-stop-live-strategy",
        name: "Hard Stop Strategies",
        miniName: "A",
        component: HardStopStrategy,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Hedging",
    icon: "ni ni-ui-04 text-red",
    state: "hedgingCollapse",
    views: [
      {
        path: "/open-exposures",
        name: "Open Exposures",
        miniName: "D",
        component: OpenExposures,
        layout: "/admin"
      },
      {
        path: "/hedging-recommandation-and-trades",
        name: "Hedging Reco. & Trade",
        miniName: "A",
        component: HedgingTrade,
        layout: "/admin"
      },
      {
        path: "/hedging-reconciliation",
        name: "Hedge Trade Reconciliation",
        miniName: "A",
        component: HedgingReconciliation,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Market Making",
    icon: "ni ni-planet text-green",
    state: "marketMakingCollapse",
    views: [
      {
        path: "/dashboard",
        name: "Liquidity",
        miniName: "D",
        component: Dashboard,
        layout: "/admin"
      },
      {
        path: "/bam-statistics",
        name: "BAM Statistics",
        miniName: "A",
        component: BAMStatistics,
        layout: "/admin"
      },
      {
        path: "/mad-trading-range",
        name: "BAM Trading Range",
        miniName: "A",
        component: MadTradingRange,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Trades Monitor",
    icon: "ni ni-book-bookmark text-info",
    state: "tradeMonitorCollapse",
    views: [
      {
        path: "/trade-reconciliation",
        name: "Trades Reconciliation",
        miniName: "B",
        component: TradeReconciliation,
        layout: "/admin"
      },
      {
        path: "/trade-reconciliation-ok",
        name: "Trades Reconciliation OK",
        miniName: "B",
        component: TradeReconciliationOK,
        layout: "/admin"
      },
      {
        path: "/trade-reconciliation-problems",
        name: "Trades Reconciliation Problems",
        miniName: "B",
        component: TradeReconciliationProblems,
        layout: "/admin"
      },
      // {
      //   path: "/buttons",
      //   name: "Buttons",
      //   miniName: "B",
      //   component: Buttons,
      //   layout: "/admin"
      // },
      // {
      //   path: "/cards",
      //   name: "Cards",
      //   miniName: "C",
      //   component: Cards,
      //   layout: "/admin"
      // },
      // {
      //   path: "/grid",
      //   name: "Grid",
      //   miniName: "G",
      //   component: Grid,
      //   layout: "/admin"
      // },
      // {
      //   path: "/notifications",
      //   name: "Notifications",
      //   miniName: "N",
      //   component: Notifications,
      //   layout: "/admin"
      // },
      // {
      //   path: "/icons",
      //   name: "Icons",
      //   miniName: "I",
      //   component: Icons,
      //   layout: "/admin"
      // },
      // {
      //   path: "/typography",
      //   name: "Typography",
      //   miniName: "T",
      //   component: Typography,
      //   layout: "/admin"
      // },
      // {
      //   collapse: true,
      //   name: "Multi Level",
      //   miniName: "M",
      //   state: "multiCollapse",
      //   views: [
      //     {
      //       path: "#pablo",
      //       name: "Third level menu",
      //       component: () => {},
      //       layout: "/"
      //     },
      //     {
      //       path: "#pablo",
      //       name: "Just another link",
      //       component: () => {},
      //       layout: "/"
      //     },
      //     {
      //       path: "#pablo",
      //       name: "One last link",
      //       component: () => {},
      //       layout: "/"
      //     }
      //   ]
      // }
    ]
  },

  {
    collapse: true,
    name: "Risk Exposure Monitor",
    icon: "ni ni-money-coins text-default",
    state: "tablesCollapse",
    views: [
      {
        path: "/tables",
        name: "Tables",
        miniName: "T",
        component: Tables,
        layout: "/admin"
      },
      {
        path: "/sortable",
        name: "Sortable",
        miniName: "S",
        component: Sortable,
        layout: "/admin"
      },
      {
        path: "/react-bs-table",
        name: "React BS Tables",
        miniName: "RBT",
        component: ReactBSTables,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Robot Trader Monitor",
    icon: "ni ni-map-big text-primary",
    state: "mapsCollapse",
    views: [
      {
        path: "/external-api-healthcheck",
        name: "External APIs",
        miniName: "G",
        component: Google,
        layout: "/admin"
      },
      {
        path: "/internal-api-healthcheck",
        name: "Internal APIs",
        miniName: "V",
        component: Vector,
        layout: "/admin"
      },
      {
        path: "/infrastructure-api-healthcheck",
        name: "Infrastructure APIs",
        miniName: "V",
        component: Vector,
        layout: "/admin"
      },
      {
        path: "/hot-trading-places",
        name: "Hot Trading Pairs",
        miniName: "V",
        component: Vector,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Accounts",
    icon: "ni ni-circle-08 text-pink",
    state: "formsCollapse",
    views: [
      {
        path: "/profile",
        name: "My profile",
        miniName: "E",
        component: Profile,
        layout: "/admin"
      },
      {
        path: "/components",
        name: "Settings",
        miniName: "C",
        component: Components,
        layout: "/admin"
      },
      {
        path: "/validation",
        name: "Support",
        miniName: "V",
        component: Validation,
        layout: "/admin"
      },
      {
        path: "/lock-Session",
        name: "Lock Session",
        miniName: "V",
        component: Lock,
        layout: "/admin"
      }
    ]
  },
  {
    path: "/widgets",
    name: "Administration",
    icon: "ni ni-badge text-green",
    component: Widgets,
    layout: "/admin"
  },
  {
    path: "/charts",
    name: "Daily Eco. Statistics",
    icon: "ni ni-chart-pie-35 text-info",
    component: Charts,
    layout: "/admin"
  },
  {
    path: "/calendar",
    name: "Eco. Calendar",
    icon: "ni ni-calendar-grid-58 text-red",
    component: Calendar,
    layout: "/admin"
  },
  {
    path: "/calendar",
    name: "High Availability",
    icon: "ni ni-umbrella-13 text-purple",
    component: Calendar,
    layout: "/admin"
  },
  {
    path: "/chat-room",
    name: "Chat Room",
    icon: "ni ni-chat-round text-red",
    component: Components,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-active-40 text-red",
    component: Login,
    layout: "/auth",
  },
];

export default routes;
